import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './CustomLandingPage.module.css';
import { totalBudget } from '../../util/api';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useConfiguration } from '../../context/configurationContext';
import { loadData as queryListings } from '../SearchPage/SearchPage.duck';

export const SearchContent = props => {
  const { title, sub, href, linkText } = props;
  return (
    <div className={css.searchFlexContent}>
      <div className={css.searchFlexTitle}>
        <FormattedMessage id={title} />
      </div>
      <div className={css.searchFlexSub}>
        <FormattedMessage id={sub} />
      </div>
      <a className={css.ctaButton} href={href}>
        <FormattedMessage id={linkText} />
      </a>
    </div>
  );
};

export const SearchSmallContent = props => {
  const { title, href } = props;
  return (
    <div className={css.griditem}>
      <div className={css.smallContentTitle}>
        <FormattedMessage id={title} />
      </div>
      <a href={href}>
        <div style={{ display: 'flex', gap: '8px', fontSize: '14px', alignItems: 'center', paddingTop: "8px" }}>
          <div>
            <FormattedMessage id="CustomLandingPage.browseSearch" />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="17"
            viewBox="0 0 8 17"
            fill="none"
          >
            <path
              d="M1.63466 5.70661L2.34199 4.99994L6.19466 8.85127C6.25676 8.91298 6.30605 8.98637 6.33968 9.0672C6.37331 9.14804 6.39063 9.23472 6.39063 9.32227C6.39063 9.40982 6.37331 9.49651 6.33968 9.57734C6.30605 9.65818 6.25676 9.73156 6.19466 9.79327L2.34199 13.6466L1.63532 12.9399L5.25132 9.32327L1.63466 5.70661Z"
              fill="#4A4A4A"
            />
          </svg>
        </div>
      </a>
    </div>
  );
};

export const SectionSearch = props => {
  const dispatch = useDispatch();
  const config = useConfiguration();
  const [fullBudget, setFullBudget] = useState();

  const global = useSelector((state) => state);
  const listingsCount = global.SearchPage?.pagination?.totalItems || 0;

  const onGetTotalListings = async () => {
    dispatch(queryListings({}, "", config));
  };

  const onGetTotalBudget = async () => {
    try {
      const res = await totalBudget({});
      if (res && res.totalBudget) {
        setFullBudget(res.totalBudget);
      };
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    // onGetTotalBudget();
    onGetTotalListings();
  }, []);

  return (
    <div style={{ backgroundColor: '#F6F6F6', borderBottom: '1px solid #D9D9D9' }}>
      <div className={css.textContent}>
        {/* <div className={css.titleContent}>
          <FormattedMessage
            id="CustomLandingPage.dataTitleContent"
          />
        </div> */}
        <div className={css.flexContent}>

          <div className={css.dataPoint}>
            <div className={css.data}>
              <FormattedMessage
                id="CustomLandingPage.totalBudgetCount" />
            </div>
            <div className={css.dataPointTitle}>
              <FormattedMessage
                id="CustomLandingPage.totalBudgetText"
              />
            </div>


          </div>
          <div className={css.dataPoint}>
            <div className={css.data}>
              {listingsCount}
            </div>
            <div className={css.dataPointTitle}>
              <FormattedMessage
                id="CustomLandingPage.liveActiveSearchText"
              />
            </div>


          </div>

          <div className={css.dataPoint}>
            <div className={css.data}>
              <FormattedMessage
                id="CustomLandingPage.membersInCountriesCount"
              />
            </div>
            <div className={css.dataPointTitle}>
              <FormattedMessage
                id="CustomLandingPage.membersInCountriesText"
              />
            </div>
          </div>
        </div>
        <div className={css.titleContent}
          style={{ marginTop: "2rem" }}>
          <FormattedMessage
            id="CustomLandingPage.titleContent"
            values={{
              firstText: <span style={{ fontWeight: 'bold' }}>Browse</span>,
              secondText: <span style={{ fontWeight: 'bold' }}>create</span>,
            }}
          />
        </div>
        <div className={css.flexContent}>
          <SearchContent
            title="CustomLandingPage.searchFlexTitle"
            sub="CustomLandingPage.searchFlexSub"
            href="/s"
            linkText="CustomLandingPage.searchFlexButton"
          />
          <SearchContent
            title="CustomLandingPage.searchFlexTitle2"
            sub="CustomLandingPage.searchFlexSub2"
            href="/l/draft/00000000-0000-0000-0000-000000000000/new/details"
            linkText="CustomLandingPage.searchFlexButton2"
          />
        </div>
        <div className={css.titleContent}
          style={{ marginTop: "2rem" }}>
          <div> <FormattedMessage
            id="CustomLandingPage.titleContentMembersSearch"
          /></div>

        </div>

        <div className={css.gridContent}>
          <div className={css.gridcontainer}>
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle4"
              href="/s?pub_categoryLevel1=real_estate"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle"
              href="/s?pub_categoryLevel1=cars"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle6"
              href="s?pub_categoryLevel1=yachts"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle3"
              href="/s?pub_categoryLevel1=jets"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle7"
              href="/s?pub_categoryLevel1=fine_wine"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle2"
              href="/s?pub_categoryLevel1=helicopters"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle8"
              href="/s?pub_categoryLevel1=rare_whisky"
            />
            <SearchSmallContent
              title="CustomLandingPage.searchSmallFlexTitle5"
              href="/s?pub_categoryLevel1=watches"
            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSearch;
